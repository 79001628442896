import store from 'store';

/**
 * Temporary place to store "feature flags"
 */

const SUBTITLES_FEATURE_FLAG = 'subtitles-feature-flag';

export const isSubtitlesFeatureEnabled = () =>
  !!store.get(SUBTITLES_FEATURE_FLAG);
