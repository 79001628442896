import { useEffect, useMemo, useRef } from 'react';
import { useMount } from 'react-use';
import {
  changeCurrentSubtitlesName,
  updateSubtitlesVisibility,
} from '../../../store/subtitles/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  subtitlesIsVisibleSelector,
  currentSubtitlesNameSelector,
} from '../../../store/subtitles/selectors';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import {
  getFile,
  getFunnelId,
} from '../../../store/sourceConfiguration/selectors';

export const useSubtitlesLocalStorageKeys = () => {
  const funnelId = useSelector(getFunnelId);
  const file = useSelector(getFile);
  const storageId = funnelId ? funnelId : file?.id;
  const storageVisibilityKeyRef = useRef(
    `voomlySubtitlesIsVisible|${storageId}`
  );
  const storageSubtitlesSelectedKeyRef = useRef(
    `voomlySubtitlesSelectedId|${storageId}`
  );

  return {
    storageVisibilityKeyRef,
    storageSubtitlesSelectedKeyRef,
  };
};

export const useSubtitlesLocalStorage = ({
  player: {
    appearance: { subtitles: subtitlesConfig },
    subtitles,
  },
}: Pick<IDefaultPropTypes, 'player' | 'file'>) => {
  const dispatch = useDispatch();
  const { storageSubtitlesSelectedKeyRef, storageVisibilityKeyRef } =
    useSubtitlesLocalStorageKeys();
  const subtitlesIsVisible = useSelector(subtitlesIsVisibleSelector);
  const currentSubtitlesName = useSelector(currentSubtitlesNameSelector);
  const enabledSubtitles = useMemo(
    () => subtitles.items.filter((s) => s.isEnabled),
    [subtitles.items]
  );

  useMount(() => {
    const shouldUseDefaultSubtitles = subtitlesConfig.showByDefault;
    const { defaultSubtitlesId } = subtitles;
    const defaultSubtitlesName = enabledSubtitles.find(
      (s) =>
        s.subtitlesId === defaultSubtitlesId || s.name === defaultSubtitlesId
    )?.name;

    const storedSubtitlesName = localStorage.getItem(
      storageSubtitlesSelectedKeyRef.current
    );

    if (!enabledSubtitles.length) {
      localStorage.removeItem(storageSubtitlesSelectedKeyRef.current);
      return;
    }

    const firstSubtitlesName = enabledSubtitles[0].name;
    const hasStoredSubtitlesNameInEnabledSubtitles = !!enabledSubtitles.find(
      (s) => s.name === storedSubtitlesName
    );
    const hasDefaultSubtitlesNameInEnabledSubtitles = !!enabledSubtitles.find(
      (s) => s.name === defaultSubtitlesName
    );

    if (
      shouldUseDefaultSubtitles &&
      defaultSubtitlesName &&
      !hasStoredSubtitlesNameInEnabledSubtitles &&
      hasDefaultSubtitlesNameInEnabledSubtitles
    ) {
      localStorage.setItem(
        storageSubtitlesSelectedKeyRef.current,
        defaultSubtitlesName
      );
      dispatch(changeCurrentSubtitlesName(defaultSubtitlesName));

      return;
    }

    if (storedSubtitlesName && hasStoredSubtitlesNameInEnabledSubtitles) {
      dispatch(changeCurrentSubtitlesName(storedSubtitlesName));

      return;
    }

    if (
      !hasDefaultSubtitlesNameInEnabledSubtitles ||
      !shouldUseDefaultSubtitles
    ) {
      localStorage.setItem(
        storageSubtitlesSelectedKeyRef.current,
        firstSubtitlesName
      );
      dispatch(changeCurrentSubtitlesName(firstSubtitlesName));

      return;
    }
  });

  useMount(() => {
    if (
      subtitlesConfig.showByDefault &&
      !localStorage.getItem(storageVisibilityKeyRef.current)
    ) {
      localStorage.setItem(storageVisibilityKeyRef.current, 'true');
      dispatch(updateSubtitlesVisibility(true));
    } else if (localStorage.getItem(storageVisibilityKeyRef.current) !== null) {
      const isSubtitlesVisible =
        localStorage.getItem(storageVisibilityKeyRef.current) === 'true';
      dispatch(updateSubtitlesVisibility(isSubtitlesVisible));
    }
  });

  // NOTE: This useEffects should be called last
  useEffect(() => {
    localStorage.setItem(
      storageVisibilityKeyRef.current,
      subtitlesIsVisible.toString()
    );
  }, [storageVisibilityKeyRef, subtitlesIsVisible]);
  useEffect(() => {
    if (currentSubtitlesName) {
      localStorage.setItem(
        storageSubtitlesSelectedKeyRef.current,
        currentSubtitlesName
      );
    }
  }, [currentSubtitlesName, storageSubtitlesSelectedKeyRef]);
};
